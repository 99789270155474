<template>
   <div v-if="type==2">
    <defaultFieldTypes
      field="subscriberList"
      :fieldAttributes="{...fieldAttributes, type:'textarea', value:subscribeValue}"
    :value="subscribeValue"></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  props: ["result","fieldAttributes"],
  computed: {
      type() {
      return this.result.valueLine.n_type;
    },
    subscribeValue() {
      let subscribeValue = "";
      if (
        typeof this.result.fields["subscriberList"]["value"] != "undefined" &&
        typeof this.result.fields["subscriberList"]["value"] == "object"
      ) {
        subscribeValue = this.result.fields["subscriberList"]["value"].join(
          ", "
        );
      }
      return subscribeValue;
    },
  },
};
</script>
